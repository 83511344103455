var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-modal", {
    staticClass: "UpdateBankModal",
    attrs: {
      "actions-class": "row--justify-end",
      "data-cy": "update-bank-modal"
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [_vm._v("Enter and confirm your bank's details")]
        },
        proxy: true
      },
      {
        key: "input",
        fn: function() {
          return [
            _c(
              "transition",
              { attrs: { name: "Transition__fade" } },
              [
                _vm.unconnectedWire
                  ? _c("base-alert", {
                      staticClass: "mb-24",
                      attrs: {
                        "data-cy": "update-modal-unconnected-alert",
                        "disable-close": true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "message",
                            fn: function() {
                              return [
                                _c("label", { staticClass: "fc-white fs-14" }, [
                                  _vm._v(
                                    "\n            We can't find a bank related to that wire routing number\n          "
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        45139127
                      )
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "UpdateBankModal__inputs column" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-5 width-100",
                      attrs: {
                        "data-cy": "update-modal-name-on-account",
                        instructions: _vm.instructions.BANK_NAME_INSTRUCTION,
                        label: true,
                        "label-for": "account-name",
                        placeholder: "What is the name on the account",
                        valid: _vm.nameOnAccount.valid
                      },
                      model: {
                        value: _vm.nameOnAccount.value,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.nameOnAccount,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "nameOnAccount.value"
                      }
                    },
                    [_vm._v("\n          Name on Account\n        ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column mb-5" },
                [
                  _c(
                    "base-input",
                    {
                      staticClass: "width-100",
                      attrs: {
                        "data-cy": "update-modal-wire-routing-number",
                        instructions:
                          _vm.instructions.ROUTING_NUMBER_INSTRUCTION,
                        label: true,
                        "label-for": "wire-routing-number",
                        "max-length": 9,
                        placeholder: "What's the wire routing number",
                        valid: _vm.wireRoutingNumber.valid
                      },
                      on: {
                        input: _vm.verifyRoutingNumber,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.verifyRoutingNumber($event)
                        }
                      },
                      model: {
                        value: _vm.wireRoutingNumber.value,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.wireRoutingNumber,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "wireRoutingNumber.value"
                      }
                    },
                    [_vm._v("\n          Wire Routing Number\n        ")]
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "Transition__opacity-fade" } },
                    [
                      _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.verificationMessage,
                              expression: "verificationMessage"
                            }
                          ],
                          staticClass:
                            "UpdateBankModal__verification-message mb-19 mt-24 width-100",
                          attrs: {
                            "data-cy":
                              "update-modal-routing-number-relationship-label"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.verificationMessage) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _vm.editing
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-light fc-white ml-auto",
                    attrs: {
                      "data-cy": "update-bank-modal-cancel-btn",
                      disabled: _vm.buttonsDisabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("\n      Cancel\n    ")]
                )
              : _vm._e(),
            _c(
              "base-button",
              {
                staticClass: "bg-blue fc-white ml-10",
                attrs: {
                  "data-cy": "update-bank-modal-update-btn",
                  disabled: !_vm.validation || _vm.buttonsDisabled
                },
                on: { click: _vm.updateBank }
              },
              [_vm._v("\n      Update\n    ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }