<template>
  <base-modal
    :actions-class="'row--justify-end'"
    class="UpdateBankModal"
    data-cy="update-bank-modal"
  >
    <template v-slot:label>Enter and confirm your bank's details</template>

    <template v-slot:input>
      <!-- Notification for a bad wire (after submitting) -->
      <!--
        NOT A DUPLICATE ERROR/USER MESSAGE
        This exists outside of the verification message because the verification
        message is based on queue'ing moov, but this covers Modern Treasury, which
        is only possible after we've submitted a request to MT.
      -->
      <transition name="Transition__fade">
        <base-alert
          v-if="unconnectedWire"
          class="mb-24"
          data-cy="update-modal-unconnected-alert"
          :disable-close="true"
        >
          <template v-slot:message>
            <label class="fc-white fs-14">
              We can't find a bank related to that wire routing number
            </label>
          </template>
        </base-alert>
      </transition>

      <div class="UpdateBankModal__inputs column">
        <div class="column">
          <base-input
            v-model.trim="nameOnAccount.value"
            class="mb-5 width-100"
            data-cy="update-modal-name-on-account"
            :instructions="instructions.BANK_NAME_INSTRUCTION"
            :label="true"
            :label-for="'account-name'"
            :placeholder="'What is the name on the account'"
            :valid="nameOnAccount.valid"
          >
            Name on Account
          </base-input>
        </div>

        <div class="column mb-5">
          <base-input
            v-model.trim="wireRoutingNumber.value"
            @input="verifyRoutingNumber"
            @keydown.enter="verifyRoutingNumber"
            class="width-100"
            data-cy="update-modal-wire-routing-number"
            :instructions="instructions.ROUTING_NUMBER_INSTRUCTION"
            :label="true"
            :label-for="'wire-routing-number'"
            :max-length="9"
            :placeholder="'What\'s the wire routing number'"
            :valid="wireRoutingNumber.valid"
          >
            Wire Routing Number
          </base-input>

          <transition name="Transition__opacity-fade">
            <label
              v-show="verificationMessage"
              class="UpdateBankModal__verification-message mb-19 mt-24 width-100"
              data-cy="update-modal-routing-number-relationship-label"
            >
              {{ verificationMessage }}
            </label>
          </transition>
        </div>
      </div>
    </template>

    <template v-slot:actions>
      <!-- v-if="editing" prevents users from skipping the update step when creating a new bank -->
      <base-button
        v-if="editing"
        @click="$emit('close')"
        class="bg-light fc-white ml-auto"
        data-cy="update-bank-modal-cancel-btn"
        :disabled="buttonsDisabled"
      >
        Cancel
      </base-button>
      <base-button
        @click="updateBank"
        class="bg-blue fc-white ml-10"
        data-cy="update-bank-modal-update-btn"
        :disabled="!validation || buttonsDisabled"
      >
        Update
      </base-button>
    </template>
  </base-modal>
</template>

<script>
// Helpers
import { ClientBankAccount } from '../utils/api'
// Components
import BaseAlert from './base-alert.vue'
import BaseButton from './base-button.vue'
import BaseInput from './base-input.vue'
import BaseModal from './base-modal.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'UpdateBankModal',

  components: {
    BaseAlert,
    BaseButton,
    BaseInput,
    BaseModal,
  },


  props: {
    bank: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
    troublesomeBank: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [ValidationMixin],

  async created () {
    if (this.bank) this.verifyRoutingNumber()
  },

  data () {
    return {
      buttonsDisabled: false,
      nameOnAccount: {
        valid: true,
        value: this.bank.bank_account_owner_name,
      },
      unconnectedWire: false, // Set in updateBank in try...catch (error)...
      verificationResult: null,
      verifying: false,
      wireRoutingNumber: {
        valid: true,
        value: this.bank.bankInfo ? this.bank.bankInfo.wire_routing : null,
      },
    }
  },

  computed: {
    validation () {
      let valid = true

      if (!this.nameOnAccount.value || !this.isValidBankName(this.nameOnAccount.value)) {
        this.$set(this.nameOnAccount, 'valid', false)
        valid = false
      } else {
        this.$set(this.nameOnAccount, 'valid', true)
      }

      if (!this.wireRoutingNumber.value || !this.isValidRoutingNumber(this.wireRoutingNumber.value)) {
        this.$set(this.wireRoutingNumber, 'valid', false)
        valid = false
      } else {
        this.$set(this.wireRoutingNumber, 'valid', true)
      }

      return valid
    },

    verificationMessage () {
      if (!this.wireRoutingNumber.valid) return false
      if (
        this.verificationResult
        && this.verificationResult.wireParticipants
        && this.verificationResult.wireParticipants.length
      ) {
        return `Found bank: ${this.verificationResult.wireParticipants[0].customerName}`
      }
      // Displaying nothing if a bank relationship is not found
      return false
    },
  },

  methods: {
    routingNumberValidation () {
      let valid = true

      if (
        !this.wireRoutingNumber.value
        || !this.isValidRoutingNumber(this.wireRoutingNumber.value)
      ) {
        this.$set(this.wireRoutingNumber, 'valid', false)
        valid = false
      } else {
        this.$set(this.wireRoutingNumber, 'valid', true)
      }

      return valid
    },

    // eslint-disable-next-line func-names
    async verifyRoutingNumber () {
      // "getRoutingNumberBankRelationshipVerification" will throw a 500 error
      // if the submitted routing number is 1 or 10 or more characters long
      // i.e. must be between 2 and 9 characters long,
      // so let's make sure we check for input validation before sending the requesting
      if (!this.routingNumberValidation()) {
        this.verificationResult = null
        return
      }

      this.verifying = true

      try {
        const result = (await ClientBankAccount.getRoutingNumberBankRelationshipVerification(
          this.wireRoutingNumber.value
        )).data
        this.verificationResult = result
      }
      catch (error) {
        this.captureSentryEvent(
          'Manual Verify Bank Modal "Verify Routing #"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
      }

      this.verifying = false
    },

    async updateBank () {
      // Make sure you're running `npm run moov` on your local if you want this to work
      if (!this.routingNumberValidation()) return

      this.progressStart()
      this.buttonsDisabled = true
      this.unconnectedWire = false

      try {
        await ClientBankAccount.postWireRoutingNumber({
          client_id: this.bank.client_id,
          id: this.bank.id,
          routing_number: this.wireRoutingNumber.value,
        })
        await ClientBankAccount.update({
          bank_account_owner_name: this.nameOnAccount.value,
          client_id: this.bank.client_id,
          id: this.bank.id,
        })
        this.$emit('bank-updated')
        this.progressFinish()
      }
      catch (error) {
        this.buttonsDisabled = false

        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.name === 'BadRequestError'
          && error.response.data.error.message.includes('connected')
        ) {
          this.unconnectedWire = true
          this.captureSentryEvent(
            'Dashboard Client Banking "Unconnected Wire Number"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.progressFail()
          return
        }
        this.captureSentryEvent(
          'Dashboard Client Banking "Update Account Details"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'We had an issue updating the client\'s bank account details' })
      }
    },
  },
}
</script>

<style lang="sass">
.UpdateBankModal
  cursor: default

  &__inputs
    overflow-y: auto

  &__verification-message
    background-color: lighten($alert, 30%)
    border: $border
    border-radius: $border-radius
    padding: 1rem
</style>
