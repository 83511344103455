<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
export default {
  name: 'PlaidLink',

  props: {
    bank: {
      type: Object,
      required: false,
      default: null
    },
    token: {
      type: String,
      required: false,
      default: null,
    },
  },

  mounted () {
    this.preparePlaidLink()
  },

  beforeDestroy() {
    this.removePlaidScript()
  },

  data () {
    return {
      plaidUrl: process.env.VUE_APP_PLAID_URL,
      product: 'auth',
    }
  },

  methods: {
    loadScript (src) {
      return new Promise(((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve()
          return
        }
        const el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
      }))
    },

    onScriptError (error) {
      this.CError('There was an issue loading the link-initialize.js script', error)
    },

    onScriptLoaded () {
      const component = this
      window.linkHandler = window.Plaid.create({
        clientName: 'Bobtail',
        env: process.env.VUE_APP_PLAID_ENV,
        key: process.env.VUE_APP_PLAID_PUBLIC_KEY,
        onExit: (error, metadata) => {
          if (error) {
            component.captureSentryEvent(
              'plaid error',
              {
                config: error.config,
                data: metadata,
                details: error,
                props: component.$data,
                response: error.response,
              }
            )
          }
        },
        onEvent: (eventName, metadata) => {
          console.log(eventName)
          if (eventName === 'SELECT_INSTITUTION') {
            console.log(metadata.institution_name)
            if (['capital one', 'pnc', 'netspend'].includes(metadata.institution_name.toLowerCase())) {
              component.$emit('adding-troublesome-bank', metadata.institution_name.toLowerCase())
            }
          }
          if (eventName === 'EXIT') component.$emit('cancelled')
        },
        onSuccess: async (token, accountResponse) => {
          console.log("plaid token account response", token, accountResponse)
          if (this.token) component.$emit('bank-updated', accountResponse)
          else component.$emit('bank-added', accountResponse)
        },
        product: component.product,
        token: component.token,
        webhook: `${process.env.VUE_APP_BASE_URL}/api/clientbankaccounts/plaidbankaccountwebhook`
      })
      window.linkHandler.open()
    },

    preparePlaidLink () {
      this.loadScript(this.plaidUrl)
        .then(this.onScriptLoaded)
        .catch(this.onScriptError)
    },

    removePlaidScript () {
      const el = document.querySelector(`script[src="${this.plaidUrl}"]`)
      const plaidLinkIframe = document.querySelector('iframe[id^="plaid-link-iframe"]')
      if (el) {
        document.head.removeChild(el)
        document.body.removeChild(plaidLinkIframe)
      }
    },
  },
}
</script>
