var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "BaseBank mr-10 mb-10",
        {
          "BaseBank--active": _vm.bank.status === "active"
        }
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            "BaseBank__content column column--justify-end",
            {
              clickable: !_vm.preventClick
            }
          ],
          on: {
            click: function($event) {
              !_vm.preventClick ? (_vm.bankOptionsModalVisible = true) : null
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "row row--wrap",
              attrs: { "data-cy": "bank-flags" }
            },
            [
              _vm.bank.status === "active"
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-green fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        ACTIVE\n      ")]
                  )
                : _vm._e(),
              !_vm.bank.bank_account_owner_name
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        NO NAME ON ACCOUNT\n      ")]
                  )
                : _vm._e(),
              _vm.bank.plaid_verification_status ===
              "pending_automatic_verification"
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        PENDING VERIFICATION\n      ")]
                  )
                : _vm._e(),
              _vm.bank.plaid_verification_status === "ITEM_LOGIN_REQUIRED"
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        VERIFICATION EXPIRED\n      ")]
                  )
                : _vm._e(),
              _vm.bank.plaid_verification_status ===
              "pending_manual_verification"
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        PENDING MICRO-DEPOSITS\n      ")]
                  )
                : _vm._e(),
              !_vm.bank.confirmed_wire &&
              _vm.bank.modern_treasury_external_account_id
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        REQUIRES WIRE ROUTING NUMBER\n      ")]
                  )
                : _vm._e(),
              _vm.bank.status === "hidden"
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        ARCHIVED\n      ")]
                  )
                : _vm._e(),
              _vm.bank.bankInfo && _vm.bank.bankInfo.error
                ? _c(
                    "label",
                    {
                      staticClass:
                        "BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
                    },
                    [_vm._v("\n        ERROR CONNECTING TO BANK\n      ")]
                  )
                : _vm._e()
            ]
          ),
          _c(
            "label",
            {
              staticClass: "fs-18 fw-med no-select",
              attrs: { "data-cy": "bank-name" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.bank.bank_name
                      ? _vm.bank.bank_name
                      : _vm.bank.bank_account_name || "Pending Bank Account"
                  ) +
                  "\n    "
              )
            ]
          ),
          _c(
            "label",
            {
              staticClass: "fs-14 fw-med mt-4 no-select",
              attrs: { "data-cy": "bank-owner-name" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    "Name on account: " + _vm.bank.bank_account_owner_name
                  ) +
                  "\n    "
              )
            ]
          ),
          _c("label", { staticClass: "fs-14 fw-med mt-4 no-select" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.bank.bank_account_full_name
                    ? _vm.bank.bank_account_full_name
                    : "Added at " +
                        new Date(_vm.bank.created_at).toLocaleDateString()
                ) +
                "\n    "
            )
          ]),
          _vm.bank.bankInfo
            ? _c(
                "label",
                {
                  staticClass: "fc-mid fs-14 mt-4 no-select",
                  attrs: { "data-cy": "bank-account-number" }
                },
                [
                  _vm._v(
                    "\n      Account Number " +
                      _vm._s(_vm.bank.bankInfo.account || "Not Available") +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm.bank.bankInfo
            ? _c(
                "label",
                {
                  staticClass: "fc-mid fs-14 mt-4 no-select",
                  attrs: { "data-cy": "bank-ach-number" }
                },
                [
                  _vm._v(
                    "\n      ACH Routing Number " +
                      _vm._s(_vm.bank.bankInfo.routing) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm.bank.bankInfo
            ? _c(
                "label",
                {
                  staticClass: "fc-mid fs-14 mt-4 no-select",
                  attrs: { "data-cy": "bank-wire-number" }
                },
                [
                  _vm._v(
                    "\n      Wire Routing Number " +
                      _vm._s(_vm.bank.bankInfo.wire_routing) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm.bankOptionsModalVisible
        ? _c("base-modal", {
            attrs: {
              "actions-class": "row--wrap",
              "data-cy": "bank-options-modal"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [_vm._v("\n      What would you like to do?\n    ")]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _vm.buttonDisplayed.activate
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-activate-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.activateBank }
                            },
                            [_vm._v("\n        Activate\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.edit
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-edit-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: {
                                click: function($event) {
                                  _vm.editing = true
                                }
                              }
                            },
                            [_vm._v("\n        Edit Account Details\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.microDeposits
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-verify-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.prepPlaidUpdate }
                            },
                            [_vm._v("\n        Verify Micro-deposits\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.itemLoginRequired
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-red fc-white fs-12 mr-8 mt-8",
                              attrs: {
                                "data-cy":
                                  "base-bank-force-item-login-required-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.flagBankItemLoginRequired }
                            },
                            [_vm._v("\n        ITEM_LOGIN_REQUIRED\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.update
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white fs-12 mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-update-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.prepPlaidUpdate }
                            },
                            [_vm._v("\n        UPDATE\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.archive
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-red fc-white mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-archive-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.confirm }
                            },
                            [_vm._v("\n        Archive\n      ")]
                          )
                        : _vm._e(),
                      _vm.buttonDisplayed.unarchive
                        ? _c(
                            "base-button",
                            {
                              staticClass: "bg-red fc-white mr-8 mt-8",
                              attrs: {
                                "data-cy": "base-bank-archive-btn",
                                disabled: _vm.disableModalButtons
                              },
                              on: { click: _vm.confirm }
                            },
                            [_vm._v("\n        Unarchive\n      ")]
                          )
                        : _vm._e(),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white mt-8",
                          attrs: {
                            "data-cy": "base-bank-cancel-btn",
                            disabled: _vm.disableModalButtons
                          },
                          on: {
                            click: function($event) {
                              _vm.bankOptionsModalVisible = false
                            }
                          }
                        },
                        [_vm._v("\n        Cancel\n      ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1777634350
            )
          })
        : _vm._e(),
      _vm.confirming
        ? _c("base-modal", {
            attrs: {
              "actions-class": "row--wrap",
              "data-cy": "bank-confirming-modal"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n      Are you sure you want to " +
                          _vm._s(
                            _vm.bank.status !== "hidden"
                              ? "archive"
                              : "unarchive"
                          ) +
                          " this bank?\n    "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-8 mt-8",
                          attrs: {
                            "data-cy": "base-bank-archive-btn",
                            disabled: _vm.disableModalButtons
                          },
                          on: { click: _vm.archiveBank }
                        },
                        [_vm._v("\n        Yes, I'm sure\n      ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white mt-8",
                          attrs: {
                            "data-cy": "base-bank-cancel-btn",
                            disabled: _vm.disableModalButtons
                          },
                          on: {
                            click: function($event) {
                              _vm.confirming = false
                              _vm.bankOptionsModalVisible = true
                            }
                          }
                        },
                        [_vm._v("\n        Cancel\n      ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3154604364
            )
          })
        : _vm._e(),
      _vm.editing
        ? _c("update-bank-modal", {
            attrs: { bank: _vm.bank, editing: true },
            on: {
              "bank-updated": _vm.bankUpdatedCallback,
              close: function($event) {
                _vm.editing = false
                _vm.bankOptionsModalVisible = true
              }
            }
          })
        : _vm._e(),
      _vm.updating
        ? _c("plaid-link", {
            attrs: { bank: _vm.bank, token: _vm.plaidPublicToken },
            on: {
              "bank-updated": _vm.plaidLinkUpdateCallback,
              cancelled: _vm.cancelledPlaidCallback
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }