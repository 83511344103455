<!-- I don't want to duplicate all the props/functions/initializing needed to
do all the plaid banking in every single instance of base-bank, so all banking
functionality will be handled by dashboard-client-banking, and this component will
only emit events to be listened to -->

<template>
  <div
    :class="['BaseBank mr-10 mb-10', {
      'BaseBank--active': bank.status === 'active',
    }]"
  >
    <!--
      Need to wrap the inner bank card content in a separate container to avoid listening to clicks
      on the outer parent element, which then triggers bankOptionsModalVisible to true on ANY click
      within the card, including the modals; however, by moving the bankOptionsModalVisible toggle
      event listener to the wrapper card content and moving the card content as a sibling to the
      various modals, we avoid triggering bankOptionsModalVisible on every click within this component.
      This allows us to ensure the bankOptionsModal closes after successfully archiving or editing.

      This is required because the update-modal and bank options modal is inside of this component
      compared to when it was in dashboard-client-banking before the refactor of 2021.
    -->
    <div
      @click="!preventClick ? bankOptionsModalVisible = true : null"
      :class="['BaseBank__content column column--justify-end', {
        'clickable': !preventClick,
      }]"
    >
      <!-- Status Labels -->
      <div
        class="row row--wrap"
        data-cy="bank-flags"
      >
        <label
          v-if="bank.status === 'active'"
          class="BaseBank__label bg-green fc-white fs-12 no-select mb-8 mr-8"
        >
          ACTIVE
        </label>
        <!--
          Although we try to force the user to verify the name on account when creating or editing
          they could still reload and have their bank in this state, because we have to create the
          bank to pre-fill the confirmation details in the update-bank-modal.
        -->
        <label
          v-if="!bank.bank_account_owner_name"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          NO NAME ON ACCOUNT
        </label>
        <label
          v-if="bank.plaid_verification_status === 'pending_automatic_verification'"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          PENDING VERIFICATION
        </label>
        <label
          v-if="bank.plaid_verification_status === 'ITEM_LOGIN_REQUIRED'"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          VERIFICATION EXPIRED
        </label>
        <label
          v-if="bank.plaid_verification_status === 'pending_manual_verification'"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          PENDING MICRO-DEPOSITS
        </label>
        <!--
          Although we try to force the user to verify the wire number when creating or editing
          they could still reload and have their bank in this state, because we have to create the
          bank to pre-fill the confirmation details in the update-bank-modal.
        -->
        <label
          v-if="!bank.confirmed_wire && bank.modern_treasury_external_account_id"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          REQUIRES WIRE ROUTING NUMBER
        </label>
        <label
          v-if="bank.status === 'hidden'"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          ARCHIVED
        </label>
        <label
          v-if="bank.bankInfo && bank.bankInfo.error"
          class="BaseBank__label bg-red fc-white fs-12 no-select mb-8 mr-8"
        >
          ERROR CONNECTING TO BANK
        </label>
      </div>

      <!-- Bank account name -->
      <!-- If bank name hasn't been returned by Algolia (yet), use available names -->
      <label
        class="fs-18 fw-med no-select"
        data-cy="bank-name"
      >
        {{
          bank.bank_name
            ? bank.bank_name
            : (bank.bank_account_name || 'Pending Bank Account')
        }}
      </label>

      <!-- Name on account -->
      <label
        class="fs-14 fw-med mt-4 no-select"
        data-cy="bank-owner-name"
      >
        {{ `Name on account: ${bank.bank_account_owner_name}` }}
      </label>

      <!--
        If bank account full name hasn't been returned by Algolia (yet),
        display the date it was added
      -->
      <label class="fs-14 fw-med mt-4 no-select">
        {{
          bank.bank_account_full_name
            ? bank.bank_account_full_name
            : `Added at ${(new Date(bank.created_at)).toLocaleDateString()}`
        }}
      </label>

      <!-- Bank account number -->
      <label
        v-if="bank.bankInfo"
        class="fc-mid fs-14 mt-4 no-select"
        data-cy="bank-account-number"
      >
        Account Number {{ bank.bankInfo.account || 'Not Available' }}
      </label>

      <!-- Bank ACH routing number -->
      <label
        v-if="bank.bankInfo"
        class="fc-mid fs-14 mt-4 no-select"
        data-cy="bank-ach-number"
      >
        ACH Routing Number {{ bank.bankInfo.routing }}
      </label>

      <!-- Bank wire routing number -->
      <label
        v-if="bank.bankInfo"
        class="fc-mid fs-14 mt-4 no-select"
        data-cy="bank-wire-number"
      >
        Wire Routing Number {{ bank.bankInfo.wire_routing }}
      </label>
    </div>

    <!-- Update Modals - Functionality related to a single bank stays inside of the base-bank -->
    <base-modal
      v-if="bankOptionsModalVisible"
      :actions-class="'row--wrap'"
      data-cy="bank-options-modal"
    >
      <template v-slot:label>
        What would you like to do?
      </template>

      <template v-slot:actions>
        <base-button
          v-if="buttonDisplayed.activate"
          @click="activateBank"
          class="bg-blue fc-white mr-8 mt-8"
          data-cy="base-bank-activate-btn"
          :disabled="disableModalButtons"
        >
          Activate
        </base-button>
        <!-- https://bobtail.atlassian.net/browse/SM-230 -->
        <!--
          We don't have somewhere to store the sensitive bank info if it's not
          completed with Plaid (can't store incomplete banks with Modern Treasury, so
          we don't want to allow any editing of account details on a bank not yet
          verified/completed with Plaid (incomplete bank)
        -->
        <base-button
          v-if="buttonDisplayed.edit"
          @click="editing = true"
          class="bg-blue fc-white mr-8 mt-8"
          data-cy="base-bank-edit-btn"
          :disabled="disableModalButtons"
        >
          Edit Account Details
        </base-button>
        <base-button
          v-if="buttonDisplayed.microDeposits"
          @click="prepPlaidUpdate"
          class="bg-blue fc-white mr-8 mt-8"
          data-cy="base-bank-verify-btn"
          :disabled="disableModalButtons"
        >
          Verify Micro-deposits
        </base-button>
        <!-- DEVELOPMENT ENVIRONMENTS ONLY -->
        <base-button
          v-if="buttonDisplayed.itemLoginRequired"
          @click="flagBankItemLoginRequired"
          class="bg-red fc-white fs-12 mr-8 mt-8"
          data-cy="base-bank-force-item-login-required-btn"
          :disabled="disableModalButtons"
        >
          ITEM_LOGIN_REQUIRED
        </base-button>
        <base-button
          v-if="buttonDisplayed.update"
          @click="prepPlaidUpdate"
          class="bg-blue fc-white fs-12 mr-8 mt-8"
          data-cy="base-bank-update-btn"
          :disabled="disableModalButtons"
        >
          UPDATE
        </base-button>
        <base-button
          v-if="buttonDisplayed.archive"
          @click="confirm"
          class="bg-red fc-white mr-8 mt-8"
          data-cy="base-bank-archive-btn"
          :disabled="disableModalButtons"
        >
          Archive
        </base-button>
        <base-button
          v-if="buttonDisplayed.unarchive"
          @click="confirm"
          class="bg-red fc-white mr-8 mt-8"
          data-cy="base-bank-archive-btn"
          :disabled="disableModalButtons"
        >
          Unarchive
        </base-button>
        <base-button
          @click="bankOptionsModalVisible = false"
          class="bg-light fc-white mt-8"
          data-cy="base-bank-cancel-btn"
          :disabled="disableModalButtons"
        >
          Cancel
        </base-button>
      </template>
    </base-modal>

    <!-- If confirming an active or archive -->
    <base-modal
      v-if="confirming"
      :actions-class="'row--wrap'"
      data-cy="bank-confirming-modal"
    >
      <template v-slot:label>
        Are you sure you want to {{ bank.status !== 'hidden' ? 'archive' : 'unarchive' }} this bank?
      </template>

      <template v-slot:actions>
        <base-button
          @click="archiveBank"
          class="bg-blue fc-white mr-8 mt-8"
          data-cy="base-bank-archive-btn"
          :disabled="disableModalButtons"
        >
          Yes, I'm sure
        </base-button>
        <base-button
          @click="confirming = false; bankOptionsModalVisible = true"
          class="bg-light fc-white mt-8"
          data-cy="base-bank-cancel-btn"
          :disabled="disableModalButtons"
        >
          Cancel
        </base-button>
      </template>
    </base-modal>

    <update-bank-modal
      v-if="editing"
      @bank-updated="bankUpdatedCallback"
      @close="editing = false; bankOptionsModalVisible = true"
      :bank="bank"
      :editing="true"
    />

    <plaid-link
      v-if="updating"
      @bank-updated="plaidLinkUpdateCallback"
      @cancelled="cancelledPlaidCallback"
      :bank="bank"
      :token="plaidPublicToken"
    />
  </div>
</template>

<script>
// Helpers
import { ClientBankAccount } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseModal from './base-modal.vue'
import PlaidLink from './plaid-link.vue'
import UpdateBankModal from './update-bank-modal.vue'

export default {
  name: 'BaseBank',

  components: {
    BaseButton,
    BaseModal,
    PlaidLink,
    UpdateBankModal,
  },

  props: {
    bankAccount: {
      type: Object,
      required: true,
    },
    // Used in client-banking to disable buttons when viewing the personalized modal
    preventClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      // We need to update bank when we use Plaid Link, so we need this.data
      // even though we have this.bankAccount
      bank: this.bankAccount,
      bankOptionsModalVisible: false,
      confirming: false,
      disableModalButtons: false,
      editing: false,
      nonProdEnvironment: !['prod', 'demo'].includes(process.env.VUE_APP_ENV),
      plaidPublicToken: null,
      updating: false,
    }
  },

  watch: {
    bankAccount () {
      this.bank = this.bankAccount
    },
  },

  computed: {
    buttonDisplayed () {
      return {
        activate:
          !this.validation.isActive
          && !this.validation.isArchived
          && !this.validation.requiresVerify
          && !this.validation.requiresReauthentication
          && this.bank.modern_treasury_external_account_id,
        archive:
          !this.validation.isActive
          && !this.validation.isArchived,
        edit:
          !this.validation.isArchived
          && !this.validation.requiresReauthentication
          && this.bank.modern_treasury_external_account_id,
        itemLoginRequired:
          !this.validation.isArchived
          && !this.validation.requiresVerify
          && !this.validation.requiresReauthentication
          && this.nonProdEnvironment,
        microDeposits:
          !this.validation.isArchived
          && this.userIsClient
          && this.validation.requiresManualVerify,
        unarchive: this.validation.isArchived,
        update:
          !this.validation.isArchived
          && this.validation.requiresReauthentication
          && this.userIsClient,
      }
    },

    validation () {
      return {
        isActive: this.bank.status === 'active',
        isArchived: this.bank.status === 'hidden',
        requiresManualVerify: this.bank.plaid_verification_status === 'pending_manual_verification',
        requiresReauthentication: this.bank.plaid_verification_status === 'ITEM_LOGIN_REQUIRED',
        requiresRoutingNumber: this.bank.confirmed_wire === null,
        requiresVerify:
          [
            'pending_automatic_verification',
            'pending_manual_verification',
          ].includes(this.bank.plaid_verification_status)
          || this.bank.confirmed_wire === null,
      }
    },
  },

  methods: {
    async activateBank () {
      this.disableModalButtons = true
      this.progressStart()

      try {
        await ClientBankAccount.update({
          id: this.bank.id,
          client_id: this.bank.client_id,
          status: 'active',
        })
        this.bankOptionsModalVisible = false
        this.$emit('bank-updated')
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Base Bank "Activate"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.activationModalDisplayed = false
        this.requestFailure({ message: 'We had an issue activating the client\'s bank accounts' })
      }

      this.disableModalButtons = false
    },

    async archiveBank () {
      this.disableModalButtons = true
      this.progressStart()

      try {
        await ClientBankAccount.update({
          id: this.bank.id,
          client_id: this.bank.client_id,
          status: this.bank.status === 'hidden' ? 'inactive' : 'hidden',
        })
        this.confirming = false
        this.$emit('bank-updated')
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Base Bank "Archive"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'We had an issue updating the client\'s bank account' })
      }

      this.disableModalButtons = false
    },

    bankUpdatedCallback () {
      this.editing = false
      this.bankOptionsModalVisible = false
      this.$emit('done-adding-bank')
      this.$emit('bank-updated')
    },

    cancelledPlaidCallback () {
      this.$emit('done-adding-bank')
      this.updating = false
      this.disableModalButtons = false
      this.bankOptionsModalVisible = true
    },

    confirm () {
      this.bankOptionsModalVisible = false
      this.confirming = true
    },

    async flagBankItemLoginRequired () {
      this.disableModalButtons = true
      this.progressStart()

      try {
        await ClientBankAccount.postItemLoginRequiredState({
          id: this.bank.id,
        })
        this.bankOptionsModalVisible = false
        this.$emit('bank-updated')
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Base Bank "Flag Bank as ITEM_LOGIN_REQUIRED"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'We had an issue flagging the client\'s bank account as ITEM_LOGIN_REQUIRED' })
      }

      this.disableModalButtons = false
    },

    async plaidLinkUpdateCallback (accountResponse) {
      this.updating = false

      try {
        const oldBank = this.bank
        this.bank = (await ClientBankAccount.update({
          ...this.bank,
          bank_account_name: accountResponse.subtype,
          bank_name: accountResponse.institution.name,
          plaid_account_id: accountResponse.account.id,
          plaid_link_session_id: accountResponse.link_session_id,
          plaid_public_token: accountResponse.public_token,
          plaid_verification_status: accountResponse.account.verification_status,
        })).data
        // If the user was re-authenticating, we don't want to show the update modal
        if (oldBank.plaid_verification_status !== 'ITEM_LOGIN_REQUIRED') this.editing = true
        else this.bankUpdatedCallback()
      }
      catch (error) {
        this.captureSentryEvent(
          'Base Bank "Plaid Link Update Callback"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'We had an issue updating the client\'s bank account' })
        this.$emit('done-adding-bank')
      }
    },

    async prepPlaidUpdate () {
      // Trigger loading indicator in dashboard-client-banking.vue
      this.$emit('started-adding-bank')
      this.disableModalButtons = true

      // Get bank's Plaid Link token from backend
      try {
        // This is passed to plaid-link.vue to auto-trigger update mode on the bank
        this.plaidPublicToken = (await ClientBankAccount.getPlaidPublicToken({ id: this.bank.id })).data.public_token

        // Re-enables the modal buttons (semantics), hides the modal, triggers Plaid Link
        this.disableModalButtons = false
        this.bankOptionsModalVisible = false
        this.updating = true
      }
      catch (error) {
        this.disableModalButtons = false
        this.captureSentryEvent(
          'Base Bank "GET Plaid Link token"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'We could not receive the bank\'s Plaid token' })
        // Kills loading indicator in dashboard-client-banking.vue
        this.$emit('done-adding-bank')
      }
    },
  },
}
</script>

<style lang="sass">
.BaseBank
  border: $border
  border-radius: $border-radius
  box-shadow: $shadow-b
  height: rem(270px)
  max-width: rem(414px)
  padding: 1rem
  width: 100%

  &--active
    border-color: $branding
    box-shadow: $shadow-a, $shadow-b

  &__content
    height: 100%

  &__label
    border-radius: $border-radius
    display: inline-block
    padding: rem(3px) rem(7px)
</style>
